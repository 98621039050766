import Config from './typeConfig';
export default {
  name: 'ExceptionPage',
  props: ['type', 'homeRoute'],
  data: function data() {
    return {
      config: Config
    };
  },
  methods: {
    backHome: function backHome() {
      if (this.homeRoute) {
        this.$router.push(this.homeRoute);
      }

      this.$emit('backHome', this.type);
    }
  }
};