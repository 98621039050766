import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import ExceptionPage from '@/components/exception/ExceptionPage';
import { mapState } from 'vuex';
export default {
  name: 'Exp404',
  components: {
    ExceptionPage: ExceptionPage
  },
  computed: _objectSpread(_objectSpread({}, mapState('setting', ['pageMinHeight'])), {}, {
    minHeight: function minHeight() {
      return this.pageMinHeight ? this.pageMinHeight + 'px' : '100vh';
    }
  })
};